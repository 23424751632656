<template>
  <v-row>
    <v-dialog
      transition="scale-transition"
      persistent
      v-model="isDialog"
      max-width="700px"
    >
      <v-card outlined>
        <v-card-title>
          <div class="subtitle-1 font-weight-bold">
            {{ $t("academic.student_list") }} : {{ paramExtra.nama }}
          </div>
          <v-spacer />
          <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form
            ref="formSearch"
            v-model="formMasterExtra"
            class="mt-2"
            lazy-validation
          >
            <div class="d-flex justify-end" :class="formMasterExtra && 'my-4'">
              <v-text-field
                v-model="searchValue"
                :label="$t('app.search')"
                :rules="[v => !!v || $t('app.required')]"
                :hide-details="formMasterExtra"
                dense
                outlined
                required
              />
              <v-btn
                outlined
                class="gradient-primary"
                dark
                :loading="loadingBtn"
                style="height:40px"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </div>
          </v-form>

          <v-data-table
            :headers="headerMasterExtra"
            :items="dataStudents"
            :loading="loading"
            fixed-header
            disable-pagination
            hide-default-footer
            class="elevation-0"
          >
            <template v-slot:item.action="{ item }">
              <v-btn
                icon
                dark
                class="primary mr-2"
                :disabled="item.is_member == 1"
                @click="accept({ extra: paramExtra.id, student: item.id })"
              >
                <v-icon small>mdi-check</v-icon>
              </v-btn>

              <v-btn
                icon
                dark
                class="primary"
                :disabled="item.is_member == 0"
                @click="decline({ extra: paramExtra.id, student: item.id })"
              >
                <v-icon small>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <Pagination
            :length="length"
            :total="total"
            :current-page="page"
            :limit="limit"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  listMember,
  acceptMember,
  declineMember
} from "@/api/admin/academic/extracurricular";

export default {
  props: {
    isDialog: {
      type: Boolean,
      required: true
    },
    paramExtra: {
      type: Object,
      required: true
    }
  },
  watch: {
    isDialog() {
      this.initData();
    }
  },
  components: {
    Pagination: () => import("@/components/Pagination")
  },
  data() {
    return {
      headerMasterExtra: [
        {
          text: this.$i18n.t("extracuricular.dialog.extracurricular_available"),
          align: "left",
          value: "name"
        },
        {
          text: this.$i18n.t("app.nis"),
          value: "nis"
        },
        {
          text: this.$i18n.t("academic.class"),
          value: "kelas"
        },
        {
          text: this.$i18n.t("master_data.student.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      masterExtra: [],
      loading: false,
      dialogEditMaster: false,
      formMasterExtra: false,
      searchValue: "",
      loadingBtn: false,
      length: 0,
      total: 0,
      limit: 10,
      page: 1,
      dataStudents: []
    };
  },
  methods: {
    async initData() {
      this.loading = true;
      const data = await listMember(this.paramExtra).then(d => d.data.data);
      this.dataStudents = data.data;
      this.length = data.last_page;
      this.total = data.total;
      this.page = data.current_page;
      this.loading = false;
    },
    closeDialog() {
      this.$emit("closeDialog");
      this.$refs.formSearch.reset();
    },
    closeEdit() {
      this.dialogEditMaster = false;
    },
    async accept(data) {
      if (confirm(this.$i18n.t("master_data.student.dialog.content_1"))) {
        this.loading = true;
        await acceptMember(data);
        this.initData();
        this.loading = false;
      }
    },
    async decline(data) {
      if (confirm(this.$i18n.t("master_data.student.dialog.content_2"))) {
        this.loading = true;
        await declineMember(data);
        this.initData();
        this.loading = false;
      }
    }
  }
};
</script>
